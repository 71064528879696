exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-space-js": () => import("./../../../src/pages/our-space.js" /* webpackChunkName: "component---src-pages-our-space-js" */),
  "component---src-pages-retreats-js": () => import("./../../../src/pages/retreats.js" /* webpackChunkName: "component---src-pages-retreats-js" */),
  "component---src-templates-retreat-landing-page-js": () => import("./../../../src/templates/retreat-landing-page.js" /* webpackChunkName: "component---src-templates-retreat-landing-page-js" */)
}

